<template>
    <div class="product-page">
        <Product-Item
            :bgBanner="bgBanner"
            :title="$lang('订单管理')"
            :titleDesc="$lang('自动同步最新订单，聚合多渠道多店铺订单统一管理')"
            :subTitle="$lang('统一处理各平台新订单，提供丰富的订单辅助功能')"
            :list="contentList"
            @more="onMore"
        />
        <Process-Order />
    </div>
</template>
  
<script>
  import bgBanner from '@/assets/order_bg_banner.png'
  import ProductItem from '@/components/ProductItem.vue'
  import ProcessOrder from '@/components/ProcessOrder.vue'
  export default {
    components: { ProductItem, ProcessOrder },
    data() {
        return {
            bgBanner,
            contentList: [
                {
                    imgUrl: require('@/assets/introduction/order_bg_page_part_01_01.png'),
                    title: this.$lang('自动同步最新订单'),
                    description: this.$lang('统一管理各平台的订单，实时监控平台订单状态的变更')
                },
                {
                    imgUrl: require('@/assets/introduction/order_bg_page_part_01_02.png'),
                    title: this.$lang('自定义面单、拣货单、配货单'),
                    description: this.$lang('系统提供各类单据模板，可供卖家按需选择')
                },
                {
                    imgUrl: 'https://www.bigseller.pro/static/images/introduction/order_bg_page_part_01_03.png',
                    title: this.$lang('订单物流追踪'),
                    description: this.$lang('实时查询订单物流信息，监控物流异常')
                }
            ]
        }
    },
    methods: {
        onMore() {
            window.open("https://www.3cliques.io/seller")
        }
    }
  }
</script>