<template>
  <div class="downloadContainer page-contact">
    <div class="download-class">
      <div class="contact-maps">
        <div class="contact-map map-sz">
          <img src="@/assets/zongbu.png" alt="深圳总部公司地址地图">
          <div class="map-title">{{ $lang('深圳总部公司地址') }}：
            <div class="map-subtitle">{{ $lang('深圳市宝安区航城街道三围社区航城大道庄边工业园厂房B栋1层') }}</div>
          </div>
        </div>
        <div class="contact-map map-hz">
          <img src="@/assets/fenbu.png" alt="深圳研发部公司地址地图">
          <div class="map-title">{{ $lang('深圳研发部公司地址') }}：
            <div class="map-subtitle">{{ $lang('深圳市龙华区民治大道58号青创园民治汇12楼') }}</div>
          </div>
        </div>
      </div>
      <WechatPhone />
    </div>
  </div>
</template>

<script>
import WechatPhone from '@/components/WechatPhone.vue'
export default {
  components: { WechatPhone }
}
</script>

<style lang='scss' scoped>
.downloadContainer {
  padding: 136px 0;
  background: url('@/assets/contact_bg.png') no-repeat center bottom;
  background-size: cover;
  .contact-title {
    margin-bottom: 35px;
    color: #323233;
    font-weight: 600;
    font-size: 52px;
    line-height: 78px;
    text-align: center;
  }
  .contact-maps {
    display: flex;
    justify-content: space-between;
    width: 1240px;
    margin: 0 auto 32px;
  }
  .contact-map {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 604px;
    height: 490px;
    padding: 16px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 23px 0px rgba(28, 35, 98, 0.06);
    img {
      width: 572px;
      height: 340px;
      margin-bottom: 26px;
      object-fit: contain;
    }
    .map-title {
      min-height: 54px;
      padding: 0 12px;
      color: #323233;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      .map-subtitle {
        font-weight: 400;
      }
    }
  }
}
</style>