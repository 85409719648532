<template>
    <div class="fixed-tools">
        <div class="fixed-tools-content">
            <div class="tools-row" @click="moreBtn">
                <img src="@/assets/wechat.png" />
                <div>{{ $lang('微信客服群') }}</div>
            </div>
            <div class="tools-row" @click="moreBtn">
                <img src="@/assets/whatsapp.png" />
                <div>Whatsapp {{ $lang('群组') }}</div>
            </div>
            <div v-if="showTop" class="tools-row" @click="backTop">
                <img src="@/assets/icon/top.png" />
                <div>{{ $lang('回到顶部') }}</div>
            </div>
        </div>
        <el-dialog
        :append-to-body="true"
        :visible.sync="dialogVisible"
        width="290px">
            <div style="margin:0 auto;text-align:center;font-size:16px;">
                <img src="https://emhsz.com/images/code-3.jpg" style="width:232px;height:232px;" />
                <p>{{ $lang('扫码联系我们') }}</p>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
  export default {
    name: 'ReturnTop',
    props: {
        showTop: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: false 
        }
    },
    methods: {
        backTop() {
            document.documentElement.scrollTop = 0;
        },
        moreBtn() {
            this.dialogVisible = !this.dialogVisible;
        }
    }
  }
</script>
  
<style lang="scss" scoped>
  .fixed-tools {
    position: fixed;
    bottom: 15%;
    right: 16px;
    z-index: 1;
    .fixed-tools-content {
        height: 260px;
        width: 230px;
        div {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            background: #fff;
            margin: 12px 0 0 170px;
        }
        >div {
            cursor: pointer;
            text-align: center;
            line-height: 60px;
            box-shadow: 0 0 12px rgba(0,0,0,.08);
        }
        >div:hover {
            background: #f8f7fc;
        }
        .tools-row {
            position: relative;
            img {
                display: inline-block;
                margin: 0 auto;
                vertical-align: middle;
            }
            div {
                position: absolute;
                right: 70px;
                width: 150px;
                min-height: 36px;
                height: auto;
                display: none;
                font-size: 13px;
                text-align: center;
                line-height: 36px;
                box-shadow: 0 8px 24px rgba(149,157,165,.2);
                background: #fff;
                color: #595959;
                top: 4px;
            }
        }
        .tools-row:hover {
            display: block;
            border: none;
            div {
                display: block;
                border: none;
            }
        }
        .tools-row::before {
            position: absolute;
            right: 100%;
            width: 10px;
            height: 100px;
            content: "";
        }
        .tools-row:hover::after {
            display: block;
        }
        .tools-row::after {
            position: absolute;
            right: 59px;
            width: 0;
            height: 0;
            display: none;
            border: 6px solid transparent;
            border-left-color: #fff;
            border-radius: 3px;
            content: "";
            top: 27px;
        }
    }
  }
</style>