<template>
    <div>
        <el-dialog
        :visible.sync="visible"
        :before-close="close"
        width="290px">
            <div style="margin:0 auto;text-align:center;font-size:16px;">
                <img src="https://emhsz.com/images/code-3.jpg" style="width:232px;height:232px;" />
                <p>{{ $lang('扫码联系我们') }}</p>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
  export default {
    name: 'DialogCode',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
  }
</script>