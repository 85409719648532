<template>
  <div class="copyrightContainer">
    <div class="copyrightInfo">Copyright © {{ $lang('深圳市易明慧进出口有限公司')}} . All Rights Reserved. 
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024208836号</a>
      &nbsp;
      <a>
        <img src="@/assets/icon/police.png" style="vertical-align:sub" />
        <!-- &nbsp; 粤公网安备 44000004111400号 -->
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Copyright'
}
</script>

<style scoped>
.copyrightContainer {
  width: 100%;
  background-color: #fff;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.copyrightInfo {
  border-top: 1px solid #f2f2f2;
  height: 84px;
  color: #616066;
  width: 70%;
  text-align: center;
  line-height: 84px;
}
.copyrightInfo a {
  font-weight: 400;
  text-decoration: none;
  color: #616066;
}
</style>