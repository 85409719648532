<template>
    <div class="product-page page_top">
        <div class="banner_box" :style="{backgroundImage: 'url('+ bgBanner +')'}">
            <div class="banner_intro_wrap">
                <h2>{{ title }}</h2>
                <p class="intro_text">{{ titleDesc }}</p>
                <el-button type="primary" class="btn-primary-more" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
            </div>
        </div>
        <div class="page_part page_part_01">
            <h3>{{ subTitle }}</h3>
            <ul class="content_wrap content_main layout_default">
                <template v-for="(item, index) in list">
                    <li v-if="index%2==0">
                        <div class="intro_img">
                            <img class="intro_img" :src="item.imgUrl" alt="">
                        </div>
                        <div class="intro_desc">
                            <h5 class="f_20 prefix">{{ item.title }}</h5>
                            <p class="f_16 f_gray mt_16">{{ item.description }}</p>
                        </div>
                    </li>
                    <li v-else>
                        <div class="intro_desc">
                            <h5 class="f_20 prefix">{{ item.title }}</h5>
                            <p class="f_16 f_gray mt_16">{{ item.description }}</p>
                        </div>
                        <img class="intro_img" :src="item.imgUrl" alt="">
                    </li>
                </template>
            </ul>
        </div>
        <DialogCode :visible="dialogVisible" @close="moreBtn" />
    </div>
</template>
  
<script>
  /**
   * @Title {String}
   */
  import bg from '@/assets/product_bg_banner.png'
  import DialogCode from '@/components/DialogCode.vue'
  export default {
    components: { DialogCode },
    props: {
        bgBanner: {
            type: String,
            default: bg
        },
        title: {
            type: String,
            default: ''
        },
        titleDesc: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        },
        obj: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        moreBtn() {
            this.$emit('more')
        }
    }
  }
</script>
  
<style lang='scss' scoped>
 .page_top {
    padding-top: 45px;
 }
 .btn-primary-more {
    min-width: 190px;
    border-radius: 2px;
    background: #4934b2;
    border-color: #4934b2;
 }
 .btn-primary-more:hover {
    background: #3d2c96;
    border-color: #3d2c96;
 }
 .banner_box {
    height: 488px;
    background: no-repeat center url('@/assets/product_bg_banner.png');
    .banner_intro_wrap {
        margin: 0 auto;
        width: 1240px;
        position: relative;
        top: 50%;
        transform: translate(2%, -50%);
        h2 {
            font-size: 38px;
            width: 654px;
            line-height: 1.4;
            font-weight: 500;
        }
        .intro_text {
            color: #616066;
            font-size: 20px;
            width: 624px;
            line-height: 1.6;
            margin-top: 28px;
        }
    }
 }
 .content_main {
    width: 1240px;
    margin: 0 auto;
 }
 .page_part {
    h3 {
        font-size: 34px;
        text-align: center;
        max-width: 1240px;
        margin: 0 auto;
    }
 }
 .page_part_01 {
    padding-top: 100px;
    margin-bottom: 96px;
    ul.content_wrap {
        margin-top: 112px;
        display: flex;
        flex-direction: column;
        li {
            display: flex;
            align-items: center;
            .intro_img {
                width: 560px;
                height: 326px;
            }
            .intro_desc {
                flex: 1;
                margin-left: 62px;
                h5 {
                    font-weight: 500;
                    display: flex;
                    align-items: flex-start;
                    font-size: 20px;
                }
                p {
                    line-height: 28px;
                    padding-left: 12px;
                    margin-top: 16px;
                    color: #919099;
                    font-size: 16px;
                }
            }
        }
    }
 }
</style>