<template>
  <div class="about">
    <div class="Banner">
      <div class="w1200">
        <div class="text-wrap">
          <div class="desc">
            <h1 class="main-title">{{ $lang('联系我们') }}</h1>
            <h1 class="main-title">{{ $lang('提前获取商机') }}</h1>
          </div>
          <el-button class="custom-btn" @click="moreBtn">{{ $lang('开启生意之旅') }}</el-button>
        </div>
        <div class="background"></div>
      </div>
    </div>
    <div class="about-group">
      <h1 class="second-title" style="margin-bottom: 8px;">{{ $lang('关于易明慧') }}</h1>
      <h3 class="title">{{ $lang('易明慧成立于2019年，是全球互联网电商引领者，中国领先的消费品数字供应链服务平台。集团现有全球员工100余人，构建深圳、中国香港多总部运营格局，在巴西国家设立控股子公司。') }}</h3>
      <div class="content">
        <img class="banner" src="@/assets/about.png" alt="">
        <ul class="list">
          <li class="item">
            <img class="icon" src="@/assets/icon/dollar.png" alt="">
            <div class="desc-item">
              <p class="desc-item__title">{{ $lang('2020年交易额') }}</p>
              <p>{{ $lang('易明慧已为近50万线上线下中小零售商提供约1000国内外消费品牌近15万SKU数字供应链服务，2020年公司交易额达1千万元。') }}</p>
            </div>
          </li>
          <li class="item">
            <img class="icon" src="@/assets/icon/logistic.png" alt="">
            <div class="desc-item">
              <p class="desc-item__title">{{ $lang('建立海外完整物流体系') }}</p>
              <p>{{ $lang('布局巴西仓库，为商家制定一对一专门的跨境电商物流与供应链解决方案，提供一站式的全球运输服务。') }}</p>
            </div>
          </li>
          <li class="item">
            <img class="icon" src="@/assets/icon/star.png" alt="">
            <div class="desc-item">
              <p class="desc-item__title">{{ $lang('为中国头部跨境电商平台供货') }}</p>
              <p>{{ $lang('对接100+国外主流电商平台，服务线上线下超过50万零售商，覆盖传统渠道、新流量直播电商、社区团购、中小商家赋能等多个领域。') }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="contact-us">
      <h1 class="second-title tip">{{ $lang('现在就联系我们') }}</h1>
      <div class="E-mail-wrap">
        <i class="el-icon-message" ></i>
        <a href="mailto: yiminghuibrazil@gmail.comm">yiminghuibrazil@gmail.com</a>
      </div>
      <div class="list">
        <el-button class="custom-btn" @click="moreBtn">{{ $lang('联营合作') }}</el-button>
        <el-button class="custom-btn" @click="moreBtn">{{ $lang('物流合作') }}</el-button>
      </div>
      <img class="top-cloud" src="@/assets/icon/cloud_top.png">
    </div>
    <DialogCode :visible="dialogVisible" @close="moreBtn" />
  </div>
</template>

<script>
import DialogCode from '@/components/DialogCode.vue'
export default {
  name: "About",
  components: { DialogCode },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    moreBtn() {
      this.dialogVisible = !this.dialogVisible;
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  padding-top: 75px;
  overflow: hidden;
}
.Banner {
  padding-bottom: 222px;
  background: linear-gradient(#e8f0ff 0%, #fff 100%);
  .w1200 {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .background {
      position: absolute;
      top: 61px;
      right: -305px;
      width: 1205px;
      height: 670px;
      background: url('@/assets/bg_about.png');
      background-size: 100% 100%;
    }
  }
  .text-wrap {
    position: relative;
    z-index: 1;
    width: 600px;
    padding-top: 218px;
  }
}
.context{
  padding: 100px 0;
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.context .title{
  font-size: 32px;
}
.context .paragraph{
  color: #999;
  font-size: 16px;
  line-height: 25px;
}
.about-group {
  width: 1240px;
  margin: 0 auto;
  padding: 100px 0;
  text-align: center;
  .second-title {
    font-size: 44px;
    line-height: 66px;
    color: #323233;
    font-weight: 600;
  }
  .title {
    margin-top: 68px;
    padding-bottom: 182px;
    color: #323233;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .banner {
      position: absolute;
      top: -129px;
      left: 0;
      width: 449px;
      height: 543px;
    }
    .list {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: justify;
        .icon {
          width: 52px;
          height: 52px;
          margin-right: 24px;
        }
        .desc-item {
          display: flex;
          flex-direction: column;
          width: 529px;
          color: #646566;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 30px;
          p {
            margin: 0;
            padding: 0;
          }
          .desc-item__title {
            margin-bottom: 12px;
            color: #323233;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
.contact-us {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background-color: #f6f7f9;
  .second-title {
    font-size: 44px;
    line-height: 66px;
    color: #323233;
    font-weight: 600;
  }
  .E-mail-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    color: #4934b2;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    .el-icon-message {
      margin-right: 9px;
    }
  }
  .top-cloud {
    position: absolute;
    top: 0;
    left: 0;
    width: 262px;
    height: 94px;
  }
}
</style>