<template>
  <div class="slidesContainer">
    <Title abstract="" :title="$lang('客户评价')" />
    <el-carousel :interval="4000" type="card" height="310px"  trigger="click">
      <el-carousel-item v-for="(item, index) in ulList" :key="index">
        <div class="li-carousel-item">
          <div class="description">
            <img src="https://static.xyb2b.com/images/02c5877efc2594202a6ad713618d3ee4.svg" alt="">
            {{ item.desc }}
          </div>
          <div class="user">
            <div class="ant-card-meta">
              <div class="ant-card-meta-avatar">
                <img :src="item.icon">
              </div>
              <div class="ant-card-meta-detail">
                <div class="title">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Title from './Title.vue'
export default {
  name: 'Slides',
  components: { Title },
  data() {
    return {
      ulList: [
        {
          name: 'Arif',
          icon: require('@/assets/icon/girl.png'),
          desc: this.$lang('3CLQUES 是一个很棒的系统，有许多工具可以帮助卖家管理店铺。 当我遇到问题时，客服响应非常快')
        },
        {
          name: 'Sally Joy',
          icon: require('@/assets/icon/boy.png'),
          desc: this.$lang('没有 3CLQUES 我们就不会这么方便处理订单。 所有订单都在一起管理，为我们节省了至少2个人力成本')
        },
        {
          name: 'Sudaryanto',
          icon: require('@/assets/icon/woman.png'),
          desc: this.$lang('使用 3CLQUES，无需下载表格，就可以进行批量编辑后刊登产品')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.slidesContainer {
  box-sizing: border-box;
  padding: 50px 150px;
  background-color: #f3f7fd;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.el-carousel__item {
  background-color: #f3f7fd;
}
.el-carousel__item img {
  width: 100%;
}
.li-carousel-item {
  // position: relative;
  border-radius: 12px;
  width: 636px;
  height: 310px;
  padding: 40px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 23px 0px rgba(53, 109, 222, 0.15);
  .description {
    margin-top: 34px;
    margin-left: 16px;
    color: #323233;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    img {
      position: absolute;
      top: 40px;
      left: 40px;
      width: 21px;
      height: 16px;
      margin-right: 12px;
    }
  }
  .user {
    position: absolute;
    right: 40px;
    bottom: 40px;
    color: #323233;
    .ant-card-meta {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      .title {
        color: #323233;
        font-weight: 600;
        font-size: 16px;
      }
      img {
        width: 86px;
        height: 86px;
        margin-left: 30px;
      }
    }
  }
}
</style>