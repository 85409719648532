import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import locale from 'element-ui/lib/locale'
const { crc32 } = require('crc');
import ElementUI from 'element-ui'
// element-ui自带多语言配置
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ptBrLocale from 'element-ui/lib/locale/lang/pt-br'
import enLocale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

const localLang = localStorage.getItem('lang')

const messages = {
    pt: {
        ...require('@/i18n/json/pt.json'), // 引入自定义的各个语言配置文件
        ...ptBrLocale
    },
    zh: {
        ...require('@/i18n/json/zh.json'),
        ...zhLocale
    },
    en: {
        ...require('@/i18n/json/en.json'),
        ...enLocale
    }
}

// 创建vue-i18n实例i18n
const i18n = new VueI18n({
    // 设置默认语言  添加多语言(每一个语言标示对应一个语言文件)
    locale: localLang || 'zh', // 语言标识，页面对应显示相同的语言
    messages
})
// 为了实现element插件的多语言切换
ElementUI.i18n((key, value) => i18n.t(key, value))

// 这里是i18next-scanner新增的配置
function lang(key) {
    // 将中文转换秤crc32格式去匹配对应的json语言包
    let hashKey = `K${crc32(key).toString(16)}`
    let words = i18n.t(hashKey)
    if (words == hashKey) {
        words = key
        console.log(key, '---无匹配语言Key')
    }
    return words
}
// 注入全局, 页面this.$lang('xxx)调用
Vue.prototype.$lang = lang

// 暴露i18n
export default i18n