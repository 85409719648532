<template>
  <div class="bottomContainer">
    <div class="productInfo">
      <h4 class="title">{{ $lang('服务') }}</h4>
      <ul class="content">
        <li>{{ $lang('交易中心') }}</li>
        <li>{{ $lang('平台合作') }}</li>
        <li>{{ $lang('平台服务') }}</li>
      </ul>
    </div>
    <div class="productInfo">
      <h4 class="title">{{ $lang('产品') }}</h4>
      <ul class="content">
        <li>
          <a href="https://www.3cliques.io/admin" target="_blank">3Cliques ERP</a>
        </li>
        <li>
          <a href="https://www.3cliques.io/admin" target="_blank">3Cliques WMS</a>
        </li>
        <li>
          <a href="https://www.3cliques.io/seller" target="_blank">3Cliques Supplier</a>
        </li>
        <li>
          <a href="https://www.seuarmazemdrop.com" target="_blank">Seuarmazemdrop</a>
        </li>
      </ul>
    </div>
    <div class="productInfo">
      <h4 class="title">3CLIQUES {{ $lang('企业') }}</h4>
      <ul class="content">
        <li>{{ $lang('关于我们') }} </li>
        <li>{{ $lang('公司历程') }} </li>
        <li>{{ $lang('加入我们') }} </li>
        <li>{{ $lang('营业执照') }} </li>
      </ul>
    </div>
    <div class="productInfo">
      <h4 class="title">{{ $lang('联系我们') }} </h4>
      <ul class="content">
        <li><i class="el-icon-location icon"></i>{{ $lang('深圳市宝安区航城街道三围社区航城大道庄边工业园厂房B栋1层') }} </li>
        <li><i class="el-icon-user-solid icon"></i>{{ $lang('合作洽谈') }} email：<a>yiminghuibrazil@gmail.com</a></li>
        <!-- <li><i class="el-icon-question icon"></i>商家网址：<a href="https://macromap.cxkjedu.com">https://macromap.cxkjedu.com</a></li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom'
}
</script>

<style scoped>
.bottomContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 30px 0 0;
}
.productInfo {
  /* padding-right: 90px; */
  width: 295px;
  height: 218px;
  display: flex;
  text-align: left;
  flex-direction: column;
  /* background-color: pink; */
}
.content {
  color: #616066;
}
.content li {
  padding-bottom: 10px;
}
.content li a {
  color: #616066;
}
.content li a:hover {
  color: #4934b2;
}
</style>