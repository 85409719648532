<template>
    <div class="index_comm_footer">
      <div class="cont flex center_align between">
          <div class="long_tail"></div>
          <div class="long_desc">
              <h2>{{ $lang('让您的业务更高效') }}</h2>
              <p>{{ $lang('更少的时间，处理更多的订单，获得更高的利润') }}</p>
              <el-button class="custom-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
          </div>
      </div>
      <DialogCode :visible="dialogVisible" @close="moreBtn" />
    </div>
  </template>
  
  <script>
  import DialogCode from '@/components/DialogCode.vue'
  export default {
    name:'ProcessOrder',
    components: { DialogCode },
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        moreBtn() {
            this.dialogVisible = !this.dialogVisible;
        }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .index_comm_footer {
    height: 300px;
    background: #f5f5f5;
    margin-bottom: 50px;
    .cont {
        position: relative;
        width: 1300px;
        margin: 0 auto;
        height: 100%;
        background: transparent;
    }
    .center_align {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .long_tail {
            width: 433px;
            height: 240px;
            margin-left: 180px;
            background: url('@/assets/index_bk_2.png') no-repeat center center;
            background-position: 0 -12px;
        }
        .long_desc {
            width: 540px;
        h2 {
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 24px;
            color: #313033;
        }
        p {
            margin-bottom: 40px;
            font-size: 18px;
            color: #919099;
        }
        }
    }
    }
</style>