<template>
    <div class="block">
        <el-carousel trigger="click" height="600px" :interval="6000">
            <el-carousel-item v-for="item in 3" :key="item">
                <div v-if="item==1" class="banner_wrap">
                    <img class="banner_pc" src="@/assets/home.png" />
                    <div class="banner_caption">
                        <div class="banner-tags">
                            <div class="banner-tag">{{ $lang('一手货源') }}</div>
                            <div class="banner-tag">{{ $lang('正品保障') }}</div>
                            <div class="banner-tag">{{ $lang('超低价格') }}</div>
                            <div class="banner-tag">{{ $lang('批发采购') }}</div>
                            <div class="banner-tag">{{ $lang('一件代发') }}</div>
                        </div>
                        <h2 class="caption_title">3CLIQUES ERP</h2>
                        <p class="caption_desc">{{ $lang('10万+拉美电商卖家的选择') }}</p>
                        <div class="banner-desc-pc">3CLIQUES {{ $lang('依托全球供应链，严选全球优质好货，业务覆盖巴西进出口业务，空海运物流业务，国际一件代发业务，巴西本土一件代发业务，为客户提供批发采购派送一站式整合解决方案') }}</div>
                        <el-button class="custom-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                    </div>
                </div>
                <div v-if="item==2" class="banner_wrap">
                    <img class="banner_pc" src="@/assets/sea_banner.png" />
                    <div class="banner_caption">
                        <div class="banner-tags">
                            <div class="banner-tag">{{ $lang('一手货源') }}</div>
                            <div class="banner-tag">{{ $lang('正品保障') }}</div>
                            <div class="banner-tag">{{ $lang('超低价格') }}</div>
                            <div class="banner-tag">{{ $lang('批发采购') }}</div>
                            <div class="banner-tag">{{ $lang('一件代发') }}</div>
                        </div>
                        <h2 class="caption_title">3CLIQUES WMS</h2>
                        <p class="caption_desc">{{ $lang('10万+拉美电商卖家的选择') }}</p>
                        <div class="banner-desc-pc">3CLIQUES {{ $lang('依托全球供应链，严选全球优质好货，业务覆盖巴西进出口业务，空海运物流业务，国际一件代发业务，巴西本土一件代发业务，为客户提供批发采购派送一站式整合解决方案') }}</div>
                        <el-button class="custom-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                    </div>
                </div>
                <div v-if="item==3" class="banner_wrap">
                    <img class="banner_pc" src="@/assets/wms_banner.png" />
                    <div class="banner_caption">
                        <div class="banner-tags">
                            <div class="banner-tag">{{ $lang('一手货源') }}</div>
                            <div class="banner-tag">{{ $lang('正品保障') }}</div>
                            <div class="banner-tag">{{ $lang('超低价格') }}</div>
                            <div class="banner-tag">{{ $lang('批发采购') }}</div>
                            <div class="banner-tag">{{ $lang('一件代发') }}</div>
                        </div>
                        <h2 class="caption_title">SEUARMAZEMDROP</h2>
                        <p class="caption_desc">{{ $lang('10万+拉美电商卖家的选择') }}</p>
                        <div class="banner-desc-pc">3CLIQUES {{ $lang('依托全球供应链，严选全球优质好货，业务覆盖巴西进出口业务，国际一件代发业务，巴西本土一件代发业务，为客户提供批发采购派送一站式整合解决方案') }}</div>
                        <el-button class="custom-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
  
<script>
  export default {
    name: 'CarouselBanner',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        moreBtn() {
           
        }
    }
  }
</script>
<style lang="scss" scoped>
.banner_wrap {
  position: relative;
  .banner_pc {
    display: block;
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  .banner_caption {
    width: 620px;
    left: calc(50% - 620px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .banner-tags {
      display: flex;
      margin-bottom: 16px;
      font-size: 20px;
      .banner-tag {
        position: relative;
        margin-right: 12px;
        padding-right: 12px;
      }
      .banner-tag:last-child {
        position: static;
      }
      .banner-tag::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 12px;
        background-color: #356dde;
        transform: translateY(-50%);
        opacity: 0.5;
        content: '';
      }
    }
    .caption_title {
      font-size: 38px;
      font-weight: 500;
      color: #313033;
      margin: 0;
      padding: 0;
    }
    .caption_desc {
      margin-bottom: 24px;
      color: #323233;
      font-size: 20px;
      line-height: 28px;
    }
    .banner-desc-pc {
      width: 600px;
      margin-bottom: 24px;
      color: #323233;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }
}
</style>