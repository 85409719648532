<template>
  <div>
  <div class="topContainer">
    <div class="title">
      <img src="../assets/logo.png" alt="logo" class="logo">
      <ul class="navigation">
        <li>
          <router-link to="/home" active-class="selectLink">{{ $lang('首页') }}</router-link>
        </li>
        <li>
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ $lang('产品') }}<i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="product">{{ $lang('产品管理') }}</el-dropdown-item>
              <el-dropdown-item command="order">{{ $lang('订单管理') }}</el-dropdown-item>
              <el-dropdown-item command="warehouse">{{ $lang('仓库管理') }}</el-dropdown-item>
              <el-dropdown-item command="report">{{ $lang('数据报表') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <router-link to="/cooperation" active-class="selectLink">{{ $lang('平台入驻') }}</router-link>
        </li>
        <li>
          <router-link to="/information" active-class="selectLink">{{ $lang('平台服务') }}</router-link>
        </li>
        <li>
          <router-link to="/about" active-class="selectLink">{{ $lang('关于我们') }}</router-link>
        </li>
        <li>
          <router-link to="/contact" active-class="selectLink">{{ $lang('联系方式') }}</router-link>
        </li>
        <li>
          <el-select
            class="language"
            v-model="language"
            @change="setLanguage"
            size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
      </ul>
    </div>
  </div>
  <Return-Top :showTop="showTop" />
</div>
</template>

<script>
import ReturnTop from '@/components/ReturnTop.vue'
export default {
  name: 'Top',
  components: {ReturnTop},
  data() {
    return {
      language: 'zh',
      options: [
        {
          value: 'zh',
          label: 'Zh',
        },
        {
          value: 'pt',
          label: 'Pt',
        },
        {
          value: 'en',
          label: 'En',
        }
      ],
      scrollTop: 0,
      showTop: false
    }
  },
  created() {
    this.language = localStorage.getItem('lang') || 'zh';
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    setLanguage(v) {
      this.language = v
      this.$i18n.locale = this.language;
      localStorage.setItem('lang', this.language);
    },
    handleCommand(command) {
      console.log('command', command)
      this.$router.push({
        path: '/' + command
      })
    },
    scrollToTop() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let that = this;
      that.scrollTop = scrollTop;
      // 为了计算距离顶部的高度，当高度大于100显示回到顶部图标，小于100则隐藏
      if (that.scrollTop > 100) {
        that.showTop = true;
      } else {
        that.showTop = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  }
}
</script>

<style scoped>
.topContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  z-index: 999;
  background-color: #fff;
  /* background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 8px -10px #333;
}
.title {
  width: 1320px;
  height: 38px;
  /* background: skyblue; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 110px;

}
.navigation {
  display: flex;
  align-items: center;
}
.navigation li {
  padding: 0 12px;
}
.navigation li a:hover,
.selectLink {
  color: #4934b2;
  cursor: pointer;
}
.freeUse{
  background-color: #fff;
  width: 126px;
  height: 32px;
  font-weight: 900;
  line-height: 26px;
  border: 2px solid #4934b2;
  border-radius: 100px;
  color: #4934b2;
}
.freeUse:hover{
  background-color: #4934b2;
  color: #fff;
}
.language {
  width: 62px;
  padding-left: 25px;
  box-sizing: content-box;
  border-left: 1px solid #eee;
}
.el-dropdown-link {
  cursor: pointer;
  color: #333;
  font-weight: 700;
}
.el-dropdown-link:hover {
  color: #4934b2;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f8f7fc;
  color: #4934b2;
}
</style>
<style>
.el-select .el-input.is-focus .el-input__inner {
  border-color: #4934b2;
}
.el-select .el-input__inner:focus {
  border-color: #4934b2;
}
.el-select-dropdown__item.selected {
  color: #4934b2;
  font-weight: 700;
}
.el-select-dropdown__item:hover {
  background-color: #f8f7fc;
}
</style>