<template>
  <div class="homeContainer">
    <div class="components-banner">
      <div class="content">
        <img class="left-ball" src="@/assets/left_ball.png">
        <div class="intro-container">
          <div class="all-service">
            <span class="service-tag">{{ $lang('品牌管理') }}</span>
            <span class="service-tag">{{ $lang('物流履约') }}</span>
          </div>
          <h1 class="second-title">{{ $lang('一站式全球商品流通解决方案') }}</h1>
          <p>{{ $lang('3CLIQUES为客户提供不止于品牌管理、物流履约等跨境供应链服务，为跨境商品全球流通提供一站式供应链解决方案。') }}</p>
          <el-button class="custom-btn" @click="moreBtn">{{ $lang('联系我们') }}</el-button>
        </div>
      </div>
    </div>
    <div class="component-logistics">
      <div class="content">
        <h1 class="second-title">{{ $lang('物流服务') }}</h1>
        <div class="logistics-process">
          <div class="process-item">{{ $lang('上游') }}<p  class="item-desc">{{ $lang('海外供货商') }}</p></div>
          <div class="process-item">{{ $lang('物流中心') }}<p class="item-desc">{{ $lang('一站式物流服务') }}</p></div>
          <div class="process-item">{{ $lang('下游') }}<p class="item-desc">{{ $lang('国内客户') }}</p></div>
        </div>
        <div class="logistics-center">
          <p class="title">{{ $lang('物流中心') }}</p>
          <div class="all-store">
            <p class="store-tag">{{ $lang('海外物流') }}</p>
            <p class="store-tag">{{ $lang('海外仓') }}</p>
            <p class="store-tag">{{ $lang('物流配送') }}</p>
            <p class="store-tag">{{ $lang('国际物流') }}</p>
            <p class="store-tag">{{ $lang('香港仓') }}</p>
            <p class="store-tag">{{ $lang('国内一般贸易仓') }}</p>
            <p class="store-tag">{{ $lang('中港物流') }}</p>
            <p class="store-tag">{{ $lang('保税仓') }}</p>
          </div>
          <el-button class="custom-btn" @click="moreBtn">{{ $lang('联系我们') }}</el-button>
          <img src="@/assets/bg_plane.png" class="img-logistics">
          <img src="@/assets/bg_cloud.png" class="img-cloud">
        </div>
      </div>
      <DialogCode :visible="dialogVisible" @close="moreBtn" />
    </div>
  </div>
</template>

<script>
import DialogCode from '@/components/DialogCode.vue'
export default {
  name: 'Service',
  components: { DialogCode },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    moreBtn() {
      this.dialogVisible = !this.dialogVisible;
    }
  }
}
</script>

<style $lang="scss" scoped>
.homeContainer {
  margin-top: 75px;
}
.components-banner {
  min-height: 680px;
  text-align: center;
  background: url('@/assets/bg_lightblue.png') no-repeat;
  background-size: 100% 680px;
  .content {
    position: relative;
    z-index: 2;
    max-width: 1920px;
    height: 610px;
    margin: 0 auto;
    padding: 100px 0 0 0;
    background: url('@/assets/bg_service.png') no-repeat;
    background-position: 600px 80px;
    background-size: 1038px 557px;
    .left-ball {
      position: absolute;
      left: 207px;
      width: 138px;
    }
    .intro-container {
      position: absolute;
      left: 50%;
      z-index: 2;
      display: block;
      max-width: 600px;
      text-align: left;
      transform: translateX(-600px);
      .all-service {
        margin-bottom: 32px;
        .service-tag {
          position: relative;
          display: inline-block;
          padding-right: 20px;
          color: #323233;
          font-size: 20px;
        }
        .service-tag:not(:last-child)::after {
          content: '';
          position: absolute;
          top: 2px;
          right: 7px;
          width: 1px;
          height: 16px;
          background-color: rgba(53, 109, 222, 0.5);
          border-radius: 2px;
        }
      }
      .second-title {
        font-size: 44px;
        line-height: 66px;
        color: #323233;
        font-weight: 600;
      }
      > p {
        margin: 32px 0;
        color: #323233;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }
}
.component-logistics {
  text-align: center;
  background-color: #f6f7f9;
  .content {
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
    padding: 100px 0;
    .second-title {
      font-size: 44px;
      line-height: 66px;
      color: #323233;
      font-weight: 600;
    }
    .logistics-process {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 68px;
      .process-item {
        position: relative;
        box-sizing: border-box;
        width: 347px;
        height: 116px;
        padding-top: 32px;
        color: #323233;
        font-weight: 600;
        font-size: 20px;
        vertical-align: middle;
        background-color: #fff;
        border-radius: 12px;
        .item-desc {
          margin-top: 16px;
          color: #646566;
          font-weight: normal;
          font-size: 16px;
        }
      }
      .process-item:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 37px;
        right: -78px;
        width: 62px;
        height: 41px;
        background: url('@/assets/icon/right-arrow.png') no-repeat;
        background-size: 62px 41px;
      }
    }
    .logistics-center {
      position: relative;
      z-index: 2;
      height: 400px;
      margin-top: 32px;
      padding-top: 80px;
      padding-left: 88px;
      text-align: left;
      background-color: #fff;
      border-radius: 12px;
      .title {
        color: #323233;
        font-size: 22px;
      }
      .all-store {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 400px;
        margin-bottom: 32px;
        .store-tag {
          box-sizing: border-box;
          position: relative;
          flex: 0 0 33.3%;
          margin-top: 13px;
          margin-bottom: 0;
          padding-left: 12px;
        }
        p::after {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 6px;
          height: 6px;
          background-color: #356DDE;
          border-radius: 6px;
        }
      }
      .img-logistics {
        position: absolute;
        top: 32px;
        right: -50px;
        z-index: -1;
        width: 956px;
        height: 449px;
      }
      .img-cloud {
        position: absolute;
        bottom: -41px;
        left: -59px;
        z-index: 11;
        width: 119px;
        height: auto;
      }
    }
  }
}
</style>