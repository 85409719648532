<template>
    <div class="product-page">
        <Product-Item
            :bgBanner="bgBanner"
            :title="$lang('数据分析报表')"
            :titleDesc="$lang('支持销售、利润、进销存及发货数据的精准分析')"
            :subTitle="$lang('多维数据分析，精准数据核算，助力卖家精细化管理和运营')"
            :list="contentList"
            @more="onMore"
        />
        <Process-Order />
    </div>
</template>
  
<script>
  import bgBanner from '@/assets/data_bg_banner.png'
  import ProductItem from '@/components/ProductItem.vue'
  import ProcessOrder from '@/components/ProcessOrder.vue'
  export default {
    components: { ProductItem, ProcessOrder },
    data() {
        return {
            bgBanner,
            contentList: [
                {
                    imgUrl: 'https://www.bigseller.pro/static/images/introduction/data_bg_page_part_01_01.png',
                    title: this.$lang('多维销售分析'),
                    description: this.$lang('全维度店铺、订单、商品销售数据统计和分析，助力卖家实现精细化销售管理')
                },
                {
                    imgUrl: require('@/assets/introduction/data_bg_page_part_01_02.png'),
                    title: this.$lang('精准利润核算'),
                    description: this.$lang('汇集促销、佣金、税费等10多项成本数据，并将费用分摊到商品级，计算结果更精准')
                },
                {
                    imgUrl: 'https://www.bigseller.pro/static/images/introduction/data_bg_page_part_01_03.png',
                    title: this.$lang('进销存分析'),
                    description: this.$lang('同步多仓进销存数据，并按新品、热销、呆滞等多维度展示，辅助采购和销售决策')
                }
            ]
        }
    },
    methods: {
        onMore() {
            window.open("https://www.3cliques.io/admin")
        }
    }
  }
</script>