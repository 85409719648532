<template>
    <div class="contact-footer">
        <div class="contacts">
          <div class="qr qr2">
            <img src="https://emhsz.com/images/code-3.jpg" alt="企业微信二维码">
            <div class="qr-desc">{{ $lang('企业微信二维码') }}</div>
          </div>
          <div class="qr qr1">
            <img src="https://emhsz.com/images/code-1.jpg" alt="WhatsApp-Jack Lee">
            <div class="qr-desc">WhatsApp-Jack Lee</div>
          </div>
          <div class="contacts">
            <div class="contact tel">
              <div class="title">{{ $lang('联系电话') }}：</div>
              <div class="list">
                <div class="number">13397727330</div>
                <div class="number">+553592534866</div>
              </div>
            </div>
            <div class="contact mail">
              <div class="title">{{ $lang('邮箱地址') }}：</div>
              <div class="list">yiminghuibrazil@gmail.com</div>
            </div>
          </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'WechatPhone'
  }
  </script>
  
<style lang="scss" scoped>
  .contact-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1240px;
    height: 212px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 23px 0px rgba(28, 35, 98, 0.06);
    .contacts {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .qr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .qr1 img {
        width: 140px;
        height: 140px;
        object-fit: contain;
      }
      .qr2 img {
        width: 140px;
        height: 140px;
        padding: 5px;
        object-fit: contain;
      }
      .contacts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 40px;
        padding-top: 5px;
        color: #323233;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        .contact {
          position: relative;
          margin-bottom: 27px;
          .title {
            font-weight: 600;
          }
          .list {
            display: flex;
            color: #646566;
            .number {
              margin-right: 13px;
            }
          }
        }
        .contact::after {
          position: absolute;
          top: 10px;
          left: -20px;
          width: 6px;
          height: 6px;
          background: #356dde;
          border-radius: 50%;
          content: '';
        }
      }
    }
  }
</style>