<template>
  <div class="homeContainer">
    <CarouselBanner></CarouselBanner>
    <div class="index_module md_2 bg_fff">
      <div class="cont flex center_align center_just column" style="width:1100px;">
        <div class="ct_1 t_c md_title f_34">3CLIQUES {{ $lang('提供全流程解决方案') }}</div>
        <div class="mt_16 md_title1">{{ $lang('专业服务拉美市场，完美解决拉美电商卖家的需求') }}</div>
        <div style="margin-top:50px;">
          <el-tabs tab-position="left" style="height: 400px;">
            <el-tab-pane :label="$lang('店铺授权')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('多平台、多店铺需要大量重复劳动？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('多平台多店铺管理') }}</p>
                      <p class="p_desc">{{ $lang('防店铺关联，一键铺货、数据搬家、定时上下架') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('采购便捷') }}</p>
                      <p class="p_desc">{{ $lang('深度对接1688，智能筛选供应商') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('上架产品') }}</p>
                      <p class="p_desc">{{ $lang('跨平台跨店铺，一键上架店铺数据') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('订单处理')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('订单拉取太麻烦？手动匹配劳神又费力？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('支持100+跨境平台') }}</p>
                      <p class="p_desc">{{ $lang('行业内数量级居高，支持全部API接口对接') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('集中式处理') }}</p>
                      <p class="p_desc">{{ $lang('订单集中式处理，店铺集中管理') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('全自动订单处理规则') }}</p>
                      <p class="p_desc">{{ $lang('自动获取物流运单号，自动拦截问题订单') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('供应链能力')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('如何提高10倍管理效率，每个员工都轻松管理？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('对接线下100+供应商') }}</p>
                      <p class="p_desc">{{ $lang('支持1688、淘宝、拼多多等采购平台') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('货源丰富') }}</p>
                      <p class="p_desc">{{ $lang('找货源、需求匹配满分') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('科学采购计算方法') }}</p>
                      <p class="p_desc">{{ $lang('智能生成采购单') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('商品管理')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('商品数据杂乱，多平台上架同一商品要反复编辑产品信息') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('SKU数字化管理') }}</p>
                      <p class="p_desc">{{ $lang('多平台、多仓库SKU库存统一管理，出入库可量化计算') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('产品库') }}</p>
                      <p class="p_desc">{{ $lang('较全的店铺和商品数据库，随时取用，随时调取') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('管理库存量') }}</p>
                      <p class="p_desc">{{ $lang('各项成本、产品内容、销量数据及采购工具一目了然') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('库存规划')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('仓库乱，找不到货？库存不准？贴标这种简单操作还需要十几个工作人员，效率低，成本又高？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('无纸化配货管理') }}</p>
                      <p class="p_desc">{{ $lang('PDA拣货，优化路径') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('作业流程标准化') }}</p>
                      <p class="p_desc">{{ $lang('库存准确率达99%，提高57%库存周转率') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('智能签收') }}</p>
                      <p class="p_desc">{{ $lang('智能打印标签，从源头控制出错自动拦截待发货订单，实现0库存发货') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('物流管理')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('海外物流跟踪难？物流成本高，不同的产品不知道选哪家物流商才省钱？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('对接100+物流服务商') }}</p>
                      <p class="p_desc">{{ $lang('对接行业数量级多') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('智能匹配') }}</p>
                      <p class="p_desc">{{ $lang('物流API对接，拉取真实运费，自动匹配最低物流运费') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('包裹跟踪') }}</p>
                      <p class="p_desc">{{ $lang('实时查看包裹跟踪信息') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('财务报表')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('账目混乱？不知道自己赚了多少，赔了多少？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('财务报表') }}</p>
                      <p class="p_desc">{{ $lang('多重维度（销售报告、商品报告 、客服报告......）明细详细') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/auth.png">
                      <p class="p_label">{{ $lang('数据分析') }}</p>
                      <p class="p_desc">{{ $lang('具体订单，具体分析') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/cloud.png">
                      <p class="p_label">{{ $lang('多平台利润报表') }}</p>
                      <p class="p_desc">{{ $lang('快速掌握及时精准的利润数据，与业务需求更加匹配') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$lang('客服管理')">
              <div class="right-wrapper">
                <div class="fill-con">
                  <p class="desc">{{ $lang('因时差无法及时回复客户问题？') }}</p>
                  <el-button size="small" class="custom-small-btn" @click="moreBtn">{{ $lang('了解更多') }}</el-button>
                </div>
                <div class="flex-fill">
                  <ul class="flex center_align">
                    <li class="li_item">
                      <img src="@/assets/icon/experience.png">
                      <p class="p_label">{{ $lang('工单处理') }}</p>
                      <p class="p_desc">{{ $lang('客户发起工单，即时与客户沟通') }}</p>
                    </li>
                    <li class="li_item">
                      <img src="@/assets/icon/service.png">
                      <p class="p_label">{{ $lang('多店铺同时处理') }}</p>
                      <p class="p_desc">{{ $lang('在3CLIQUES后台即可完成不同店铺的客服工作，一目了然且清晰高效') }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>  
      </div>
    </div>
    <div class="index_module md_2 bg_fff">
      <div class="cont flex center_align center_just column" style="width:1100px;">
        <div class="ct_1 t_c md_title f_34">3CLIQUES {{ $lang('满足不同用户需求') }}</div>
        <div class="mt_16 md_title1">{{ $lang('专业服务拉美市场，完美解决拉美电商卖家的需求') }}</div>
        <div style="margin-top:50px;display:flex;justify-content:space-between;">
          <div class="demand-col">
            <div class="bg-top">
              <p class="business">3CLIQUES ERP</p>
              <p class="business-desc">
                {{ $lang('适合中大型跨境卖家') }} </br>
                {{ $lang('解决店铺采购、发货、管理等难题') }}
              </p>
              <img  />
            </div>
            <ul class="product-list">
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('供应链深度管理') }}</p>
                <p class="f-gray-50">{{ $lang('与1688采购深度对接，智能筛选优质供应商') }}</p>
              </li>
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('提高资金库存周转效率') }}</p>
                <p class="f-gray-50">{{ $lang('库存周转控制在15天内，随着销量变化计算采购量') }}</p>
              </li>
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('标准的业务流程') }}</p>
                <p class="f-gray-50">{{ $lang('实现流程化管控，打通产品开发、销售刊登环节，完成全流程闭环') }}</p>
              </li>
            </ul>
            <div style="margin:80px 0 20px;text-align:center">
              <el-button size="small" class="custom-small-btn" @click="openBtn('erp')">{{ $lang('了解更多') }}</el-button>
            </div>
          </div>
          <div class="demand-col">
            <div class="bg-top">
              <p class="business">3CLIQUES WMS</p>
              <p class="business-desc">
                {{ $lang('适合自发货需求的跨境卖家') }} </br>
                {{ $lang('实现仓储合理规划，降低人员依赖') }}
              </p>
              <img  />
            </div>
            <ul class="product-list">
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('全仓库库位设置') }}</p>
                <p class="f-gray-50">{{ $lang('商品、库位条码化，扫码作业，精准指引不出错') }}</p>
              </li>
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('支持PDA设备') }}</p>
                <p class="f-gray-50">{{ $lang('无纸化拣货，二次分拣，库存动态盘点，多批次分区作业灵活高效') }}</p>
              </li>
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('支持10种发货模式') }}</p>
                <p class="f-gray-50">{{ $lang('采购来货，智能匹配采购单，自动推荐空余库位和路径指引') }}</p>
              </li>
            </ul>
            <div style="margin:80px 0 20px;text-align:center">
              <el-button size="small" class="custom-small-btn" @click="openBtn('wms')">{{ $lang('了解更多') }}</el-button>
            </div>
          </div>
          <div class="demand-col">
            <div class="bg-top">
              <p class="business">SEUARMAZEMDROP</p>
              <p class="business-desc">
                {{ $lang('适合海外代发需求的跨境用户') }} </br>
                {{ $lang('提供智慧化一站式解决方案') }}
              </p>
              <img  />
            </div>
            <ul class="product-list">
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('标准的业务流程') }}</p>
                <p class="f-gray-50">{{ $lang('巴西本土自发货') }}</p>
              </li>
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('订单全链路跟踪管理') }}</p>
                <p class="f-gray-50">{{ $lang('直接操作下单，一站式连接商家和承运商') }}</p>
              </li>
              <li class="li-item">
                <p class="f-gray f-gray-50">{{ $lang('多平台在途信息实时反馈') }}</p>
                <p class="f-gray-50">{{ $lang('实时在途追踪，异常状况随时提醒，运输过程可追溯') }}</p>
              </li>
            </ul>
            <div style="margin:80px 0 20px;text-align:center">
              <el-button size="small" class="custom-small-btn" @click="openBtn('sea')">{{ $lang('了解更多') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index_module md_2 bg_fff">
      <div class="cont flex center_align center_just column">
          <div class="ct_1 t_c md_title f_34">{{ $lang('拉美主流电商官方认证服务商') }}</div>
          <div class="mt_16 md_title1">{{ $lang('专业服务拉美市场，完美解决拉美电商卖家的需求') }}</div>
          <div class="cooperation_box flex wrap center_align">
              <div class="item" data-rel="shopify">
                <img src="@/assets/icon/shopify.png">
              </div>
              <div class="item" data-rel="woocommerce">
                <img src="@/assets/icon/woo.png">
              </div>
              <div class="item" data-rel="shopee">
                <img src="@/assets/icon/shopee.png">
              </div>
              <div class="item" data-rel="tiki">
                <img src="@/assets/icon/tiki.png">
              </div>
              <div class="item" data-rel="thisshop">
                <img style="margin: 0 86px" src="@/assets/icon/thisshop.png">
              </div>
              <div class="item" data-rel="sendo">
                <img src="@/assets/icon/sendo.png">
              </div>
              <div class="item" data-rel="pgmall">
                <img src="@/assets/icon/pgmall.png">
              </div>
              <div class="item" data-rel="lazada">
                <img style="margin-left:20px;" src="@/assets/icon/lazada.png">
              </div>
              <div class="item" data-rel="akulaku">
                <img src="@/assets/icon/akulaku.png">
              </div>
              <div class="item" data-rel="tiktok">
                <img src="@/assets/icon/tiktok.png">
              </div>
          </div>
      </div>
    </div>
    <div class="index_module md_5 bg_fff">
      <div class="bg_item"></div>
      <div class="cont flex center_align center_just column">
          <div class="t_c md_title f_34">{{ $lang('亮点功能') }}</div>
          <div class="rows flex">
              <ul class="rows_list box box1">
                  <li class="list_item">
                      <h4 class="h4_text">{{ $lang('产品采集搬家') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('跨平台采集、搬家，并且可以一键批量刊登') }}</div>
                      <div class="text_2 scroll">
                          <p>{{ $lang('支持采集产品，店铺搬家，多平台刊登，一键翻译，批量编辑，添加水印等功能') }}</p>
                      </div>
                  </li>
                  <li class="list_item r_list_item_5">
                      <h4 class="h4_text">{{ $lang('自动化运营') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('提供多款运营工具，完美提高运营效率') }}</div>
                      <div class="text_2 scroll">
                          <p>{{ $lang('支持自动回评、自动置顶、定时发布产品等功能') }}</p>
                      </div>
                  </li>
              </ul>
              <ul class="rows_list box box2">
                  <li class="list_item">
                      <h4 class="h4_text">{{ $lang('高效订单处理') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('批量处理多店铺订单，提高订单处理效率，减少发货出错率') }}</div>
                      <div class="text_2 scroll">
                          {{ $lang('支持自定义面单、拣货单，波次发货，物流追踪、管理退货、扫描验货等功能') }}</div>
                  </li>
                  <li class="list_item r_list_item_4">
                      <h4 class="h4_text">{{ $lang('图片水印功能') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('自定义水印模板，一键批量添加水印') }}</div>
                      <div class="text_2 scroll">
                          <p>{{ $lang('支持自定义保存多个水印模板，可以一键添加到所有产品图片') }}</p>
                      </div>
                  </li>
              </ul>
              <ul class="rows_list box box3">
                  <li class="list_item">
                      <h4 class="h4_text">{{ $lang('面单自定义') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('支持按需设置自定义面单模板') }}</div>
                      <div class="text_2 scroll">
                          {{ $lang('支持自定义面单信息，系统提供多款模板，可以按需选择内容') }}</div>
                  </li>
                  <li class="list_item">
                      <h4 class="h4_text">{{ $lang('销售数据统计') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('多维度数据报表，帮助实现数据精准分析') }}</div>
                      <div class="text_2 scroll">
                          {{ $lang('支持按店铺、订单、产品、仓库多个维度生成数据报表') }}</div>
                  </li>
              </ul>
              <ul class="rows_list box box4">
                  <li class="list_item">
                      <h4 class="h4_text">{{ $lang('多维度库存管理') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('智能管理库存，提高仓库准确率') }}</div>
                      <div class="text_2 scroll">
                          {{ $lang('支持统一管理多平台多仓库的库存，处理订单时系统智能扣减库存，并且根据销售数据提供科学的采购建议，多维度有效防止库存超卖') }}</div>
                  </li>
                  <li class="list_item">
                      <h4 class="h4_text">{{ $lang('精准物流追踪') }}</h4>
                      <span class="line"></span>
                      <div class="text_1">{{ $lang('自动追踪已发货包裹物流，可实时查看最新物流信息') }}</div>
                      <div class="text_2 scroll">
                          <p>{{ $lang('支持实时查看包裹物流信息，对接全球物流查询平台17Track，帮您随时随地掌握包裹动态') }}</p>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
    </div>
    <div class="index_module md_6 bg_fff" data-lang="zh">
        <div class="cont">
            <div class="rows t_c md_title f_34">
                3CLIQUES {{ $lang('为你保驾护航') }}</div>
            <div class="rows">
                <ul class="flex center_align">
                    <li class="li_item">
                        <img src="@/assets/icon/service.png">
                        <h4 class="h4_label">{{ $lang('客户服务') }}</h4>
                        <p class="p_desc">{{ $lang('6（天）x 12（小时）在线客户服务，及时快速解决问题') }}</p>
                    </li>
                    <li class="li_item">
                        <img src="@/assets/icon/experience.png">
                        <h4 class="h4_label">{{ $lang('经验丰富') }}</h4>
                        <p class="p_desc">{{ $lang('团队深耕一站式电商管理平台8年+') }}</p>
                    </li>
                    <li class="li_item">
                        <img src="@/assets/icon/auth.png">
                        <h4 class="h4_label">{{ $lang('官方授权') }}</h4>
                        <p class="p_desc">{{ $lang('拉美主流平台官方授权的第三方电商ERP') }}</p>
                    </li>
                    <li class="li_item">
                        <img src="@/assets/icon/encryption.png">
                        <h4 class="h4_label">{{ $lang('加密技术') }}</h4>
                        <p class="p_desc">{{ $lang('我们使用SSL来确保您与3CLIQUES之间的通信已加密') }}</p>
                    </li>
                    <li class="li_item">
                        <img src="@/assets/icon/cloud.png">
                        <h4 class="h4_label">{{ $lang('安全云服务') }}</h4>
                        <p class="p_desc">{{ $lang('我们使用在数据安全方面享有盛誉的云服务') }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <Slides />
    <div class="home-content-contact">
      <div class="home-content-con-box">
        <div class="home-con-title">{{ $lang('联系我们') }}</div>
        <div class="subtitle">{{ $lang('提前获取商机') }}</div>
        <el-button round class="business-travel" @click="moreBtn">{{ $lang('开启生意之旅') }}</el-button>
      </div>
      <WechatPhone />
    </div>
    <Process-Order />
    <DialogCode :visible="dialogVisible" @close="moreBtn" />
  </div>
</template>

<script>
import CarouselBanner from '@/components/CarouselBanner.vue'
import ProcessOrder from '@/components/ProcessOrder.vue'
import WechatPhone from '@/components/WechatPhone.vue'
import Slides from '@/components/Slides.vue'
import DialogCode from '@/components/DialogCode.vue'
export default {
  name: 'Home',
  components: { CarouselBanner, ProcessOrder, WechatPhone, Slides, DialogCode },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    moreBtn() {
      this.dialogVisible = !this.dialogVisible;
    },
    openBtn(val) {
      switch (val) {
        case 'erp':
          window.open('https://www.3cliques.io/admin')
          break;
        case 'wms':
          window.open('https://www.3cliques.io/admin')
          break;
        case 'sea':
          window.open('https://www.seuarmazemdrop.com')
          break;
      }
    }
  }
}
</script>

<style $lang="scss" scoped>
.homeContainer {
  padding-top: 75px;
}
.banner_wrap {
  position: relative;
  .banner_pc {
    display: block;
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  .banner_caption {
    width: 620px;
    left: calc(50% - 620px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .banner-tags {
      display: flex;
      margin-bottom: 16px;
      font-size: 20px;
      .banner-tag {
        position: relative;
        margin-right: 12px;
        padding-right: 12px;
      }
      .banner-tag:last-child {
        position: static;
      }
      .banner-tag::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 12px;
        background-color: #356dde;
        transform: translateY(-50%);
        opacity: 0.5;
        content: '';
      }
    }
    .caption_title {
      font-size: 38px;
      font-weight: 500;
      color: #313033;
      margin: 0;
      padding: 0;
    }
    .caption_desc {
      margin-bottom: 24px;
      color: #323233;
      font-size: 20px;
      line-height: 28px;
    }
    .banner-desc-pc {
      width: 600px;
      margin-bottom: 24px;
      color: #323233;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }
}
.cont {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  height: 100%;
  background: transparent;
  .f_34 {
    font-weight: 400;
    font-size: 34px;
    line-height: 1.5;
    color: #313033;
  }
}
.index_module.md_2 {
  .center_align {
    .md_title {
      margin-top: 90px;
      text-align: center;
    }
    .md_title1 {
      margin-top: 16px;
      font-size: 18px;
      line-height: 1.5;
      color: #919099;
      text-align: center;
    }
    .cooperation_box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 44px 0 75px;
    }
  }
}
.index_module.md_5 {
  position: relative;
  overflow: hidden;
  height: 919px;
  .bg_item {
    position: absolute;
    width: 100%;
    height: 668px;
    bottom: 100px;
    background: linear-gradient(90deg, #fff, #ececf9);
    transform: skewY(-5deg);
  }
  .cont::before {
    content: "";
    display: inline-block;
    position: absolute;
    right: -495px;
    top: 51px;
    width: 500px;
    height: 108px;
    background-image: url('@/assets/index_skew_1.png');
    background-repeat: no-repeat;
  }
  .cont::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 10px;
    left: -415px;
    width: 960px;
    height: 141px;
    background-image: url('@/assets/index_skew_2.png');
    background-repeat: no-repeat;
  }
  .center_align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .md_title {
      margin-bottom: 80px;
    }
    .rows {
      display: flex;
      padding: 60px 0;
      height: 788px;
      .rows_list {
        .list_item {
          box-sizing: border-box;
          position: relative;
          overflow: hidden;
          margin-bottom: 20px;
          border-radius: 6px;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
          padding: 59px 35px;
          width: 280px;
          min-height: 220px;
          background: #fff;
          cursor: pointer;
          .text_h4 {
            margin: 0 0 16px;
            font-size: 18px;
            font-weight: 600;
          }
          .line {
            display: block;
            margin-bottom: 27px;
            width: 28px;
            border-bottom: 2px solid #4934b2;
          }
          .text_1 {
            color: #919099;
          }
          .text_2 {
            box-sizing: border-box;
            position: absolute;
            bottom: -320px;
            width: 100%;
            left: 0;
            height: 100%;
            padding: 77px 35px 0;
            border-radius: 6px;
            transition: all 0.3s ease;
            background: #5653b8;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            overflow-y: auto;
            p {
              margin: 0;
              padding: 0;
              word-break: break-word;
            }
          }
          .text_2::before {
            content: '';
            position: absolute;
            top: 49px;
            left: 35px;
            width: 28px;
            height: 2px;
            background: #fff;
          }
        }
        .list_item:hover {
          .text_2 {
            bottom: 0;
          }
        }
      }
      .box1 {
        margin-top: 40px;
      }
      .box2 {
        margin-left: 30px;
        margin-top: -70px;
      }
      .box3 {
        margin-left: 30px;
        margin-top: -20px;
      }
      .box4 {
        margin-left: 30px;
        margin-top: -50px;
      }
    }
  }
}
.index_module.md_6 {
  padding-bottom: 98px;
  .md_title {
    text-align: center;
  }
  .center_align {
    margin-top: 65px;
    display: flex;
    justify-content: space-between;
    align-items: initial;
    .li_item {
      flex: 0 0 236px;
      min-width: 0;
      text-align: center;
      .h4_label {
        font-weight: bold;
        font-size: 18px;
        color: #313033;
        margin-top: 11px;
      }
      .p_desc {
        display: inline-block;
        width: 190px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #919099;
        word-break: break-word;
      }
    }
  }
}
.home-content-contact {
  background: #f6f7f9;
  padding: 50px 0 80px;
  .home-content-con-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 1240px;
    margin: 0 auto;
    padding: 100px 0 10px;
    background: #fff;
    border-radius: 20px 20px 0 0;
    .home-con-title {
      color: #323233;
      font-weight: 600;
      font-size: 44px;
      line-height: 66px;
    }
    .subtitle {
      margin-top: 40px;
      color: #323233;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }
    .business-travel {
      margin: 40px 0;
      background-color: #4934b2;
      border-color: #4934b2;
      color: #fff;
    }
    .business-travel:hover {
      background-color: #3d2c96;
    }
  }
}
.right-wrapper {
  .fill-con {
    height: 182px;
    background: url('@/assets/solution-bg.png') no-repeat center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 20px;
    text-align: LEFT;
    color: #4e5969;
    line-height: 30px;
    margin-bottom: 20px;
    padding-left: 50px;
    .desc {
      margin: 0;
      width: 567px;
      display: block;
      padding: 33px 0 20px 0;
      box-sizing: content-box;
    }
  }
  .flex-fill {
    background-color: #fdfdfe;
    border-radius: 10px;
    .flex {
      display: flex;
      .li_item {
        width: 33.33%;
        border-right: 1px solid #f0f2f5;
        padding: 20px;
        .p_label {
          font-size: 18px;
          color: #1d2129;
          line-height: 24px;
          margin-bottom: 8px;
        }
        .p_desc {
          font-size: 14px;
          color: #86909c;
          line-height: 22px;
        }
      }
      .li_item:last-child {
        border: 0;
      }
    }
  }
}
.demand-col {
  width: 300px;
  background: #fdfdfe;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s linear all;
  .bg-top {
    position: relative;
    background: #4934b2;
    padding: 30px;
    color: #fff;
    .business {
      margin: 0;
      margin-bottom: 23px;
      font-size: 20px;
    }
    .business-desc {
      margin: 0;
    }
  }
  .product-list {
    padding-top: 20px;
    .li-item {
      position: relative;
      padding: 0 20px 20px 40px;
      .f-gray-50 {
        margin: 0;
        color: #86909c;
      }
      .f-gray {
        color: #1d2129;
        line-height: 22px;
        padding-bottom: 0;
      }
    }
    .li-item::before {
      display: block;
      content: "";
      position: absolute;
      top: 7px;
      left: 20px;
      width: 8px;
      height: 8px;
      background: #5653b8;
      border-radius: 50%;
    }
  }
}
.demand-col:hover {
  transform: translateY(-2%);
}
.custom-small-btn {
  background-color: #4934b2;
  border-color: #4934b2;
  color: #fff;
  border: 1px solid transparent;
  &:hover, &:focus, &:active {
    background: #3d2c96;
    border-color: #3d2c96;
    color: #fff;
  }
}
</style>
<style>
.el-tabs__nav-wrap {
  background-color: #fdfdfe;
}
.el-tabs__item {
  height: 50px !important;
  line-height: 50px !important;
  padding: 0 40px !important;
}
.el-tabs__active-bar {
  background-color: #4934b2 !important;
}
.el-tabs__item:hover {
  color: #4934b2 !important;
}
.el-tabs__item.is-active {
  background-color: #f8f7fc;
  color: #4934b2 !important;
}
</style>