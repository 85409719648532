<template>
    <div class="product-page">
        <Product-Item
            :title="$lang('产品管理')"
            :titleDesc="$lang('一键同步多渠道、多店铺产品，进行批量编辑和刊登产品')"
            :subTitle="$lang('提高运营效率，产品快速高效刊登到多平台店铺')"
            :list="contentList"
            @more="onMore"
        />
        <Process-Order />
    </div>
</template>
  
<script>
  import ProductItem from '@/components/ProductItem.vue'
  import ProcessOrder from '@/components/ProcessOrder.vue'
  export default {
    components: { ProductItem, ProcessOrder },
    data() {
        return {
            contentList: [
                {
                    imgUrl: 'https://www.bigseller.pro/static/images/introduction/product_bg_page_part_01_01.png',
                    title: this.$lang('主流平台一键批量刊登'),
                    description: this.$lang('支持同步、刊登产品至Shopify、Woocommerce、Cartpanda、Yampi等10+拉美电商平台')
                },
                {
                    imgUrl: require('@/assets/introduction/product_bg_page_part_01_02.png'),
                    title: this.$lang('采集、搬家认领、推荐分类'),
                    description: this.$lang('产品一键认领到多个渠道，快速扩张品牌')
                },
                {
                    imgUrl: require('@/assets/introduction/product_bg_page_part_01_03.png'),
                    title: this.$lang('批量编辑工具'),
                    description: this.$lang('帮助您快速优化产品标题、描述，调整价格和库存，并支持一键批量添加水印')
                }
            ]
        }
    },
    methods: {
        onMore() {
            window.open("https://www.3cliques.io/list")
        }
    }
  }
</script>