import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Join from '../views/Join.vue'
import  About from '../views/About.vue'
import DownLoad from '../views/DownLoad.vue'
import Cooperation from  '../views/Cooperation.vue'
import Information from  '../views/Information.vue'
import Product from '../views/Product.vue'
import Order from  '../views/Order.vue'
import Warehouse from  '../views/Warehouse.vue'
import Report from  '../views/Report.vue'
Vue.use(VueRouter)
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    redirect: '/home' 
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/join',
    component: Join
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/contact',
    component: DownLoad
  },
  {
    path: '/cooperation',
    component: Cooperation
  },
  {
    path: '/information',
    component: Information,
    meta: { requiresAuth: true }
  },
  {
    path: '/product',
    component: Product
  },
  {
    path: '/order',
    component: Order
  },
  {
    path: '/warehouse',
    component: Warehouse
  },
  {
    path: '/report',
    component: Report
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
