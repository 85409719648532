import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './element/components.js'
import  axios from 'axios'
import i18n from './i18n/index'
import './styles/variable.scss'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
