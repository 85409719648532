<template>
  <div class="cooperationCon">
    <div class="components-banner">
      <div class="content">
        <h1 class="main-title">{{ $lang('商家合作 为万千商户供给消费品') }}</h1>
        <div class="intro-container">
          <div class="flex-item">
            <p class="number-main">100+</p>
            <p class="number-label">{{ $lang('主流渠道平台') }}</p>
          </div>
          <div class="flex-item">
            <p class="number-main">{{ $lang('10万') }}+</p>
            <p class="number-label">{{ $lang('线上线下零售商') }}</p>
          </div>
          <div class="flex-item">
            <p class="number-main">10+</p>
            <p class="number-label">{{ $lang('全球仓储') }}</p>
          </div>
          <div class="flex-item">
            <p class="number-main">10+</p>
            <p class="number-label">{{ $lang('专业品牌运营策划') }}</p>
          </div>
        </div>
        <img src="@/assets/bg_cooperation.png" style="width: 100%;">
      </div>
    </div>
    <div class="component-cooperation">
      <div class="content">
        <h1 class="second-title">{{ $lang('商家合作模式') }}</h1>
        <div class="tabs-container">
          <div class="ant-tabs ant-tabs-top ant-tabs-centered">
            <div class="ant-tabs-content-holder">
              <div class="ant-tabs-content ant-tabs-content-top">
                <div class="ant-tabs-tabpane ant-tabs-tabpane-active">
                  <div class="tab-main">
                    <p class="main-intro">{{ $lang('行云货仓面向B端提供一站式采购平台，帮助零售商摆脱复杂的采购寻源、线上运营、物流清关、末端配送环节，降低供应链成本，实现降本增效。欢迎品牌方/总代、三级链路及以上链路非品牌方的经销商、贸易商加入，入驻后开通供货功能。') }}</p>
                    <div class="adv-main">
                      <div class="adv-item">
                        <img src="@/assets/icon/first.png">
                        <div>
                          <p class="p-label">{{ $lang('销售渠道广') }}</p>
                          <p>100+{{ $lang('主流电商平台 ，50万+中小零售商') }}</p>
                        </div>
                      </div>
                      <div class="adv-item">
                        <img src="@/assets/icon/second.png">
                        <div>
                          <p class="p-label">{{ $lang('履约时效快') }}</p>
                          <p>{{ $lang('仓配体系领先，商品分拣专业') }}</p>
                        </div>
                      </div>
                      <div class="adv-item">
                        <img src="@/assets/icon/third.png">
                        <div>
                          <p class="p-label">{{ $lang('运营专业') }}</p>
                          <p>{{ $lang('专属品牌团队，打造优质品牌') }}</p>
                        </div>
                      </div>
                      <div class="adv-item">
                        <img src="@/assets/icon/fourth.png">
                        <div>
                          <p class="p-label">{{ $lang('售后服务专业') }}</p>
                          <p>{{ $lang('独立客服团队 客户体验有保障') }}</p>
                        </div>
                      </div>
                      <div class="adv-item">
                        <img src="@/assets/icon/fifth.png">
                        <div>
                          <p class="p-label">{{ $lang('快速回笼资金') }}</p>
                          <p>{{ $lang('无资金压力') }}</p>
                        </div>
                      </div>
                      <div class="adv-item">
                        <img src="@/assets/icon/sixth.png">
                        <div>
                          <p class="p-label">{{ $lang('无库存压力') }}</p>
                          <p>{{ $lang('货权买断，快速周转') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="join-main" style="margin-top:50px;text-align:center;">
        <el-button round class="business-travel" @click="settleBtn">{{ $lang('立即入驻') }}</el-button>
      </div>
    </div>
  </div>
  <div class="component-settled">
    <div class="content">
      <h1 class="second-title">{{ $lang('入驻攻略') }}</h1>
      <div class="settled-main">
        <div class="steps-main">
          <div class="step-item ">
            <p class="step-title">
              <span class="num-1">01</span>
              {{ $lang('资料准备') }}
            </p>
            <p class="step-desc">{{ $lang('仔细阅读公司注册地对应的公司资质要求，并提前准备相应材料。并将准备资料加盖入驻公司公章（鲜章）') }}</p>
            <p class="step-desc">{{ $lang('请准备结算账户银行信息') }}</p>
          </div>
          <div class="step-item ">
            <p class="step-title">
              <span class="num-1">02</span>
              {{ $lang('入驻申请') }}
            </p>
            <p class="step-desc">{{ $lang('选择合作的业务模块（自营合作、联营合作）并点击进入入驻页面') }}</p>
            <p class="step-desc">{{ $lang('勾选须知，查看入驻引导地图，填写联系人信息和公司基本信息') }}</p>
          </div>
          <div class="step-item ">
            <p class="step-title">
              <span class="num-1">03</span>{{ $lang('信息完善') }}
            </p>
            <p class="step-desc">{{ $lang('填写公司信息，根据页面指示，提交必填的证照资料。证照资料请加盖入驻公司公章（鲜章）') }}</p>
            <p class="step-desc">{{ $lang('根据页面及邮件提示准备后续计划合作的类目及品牌证照资料，提高入驻效率') }}</p>
            <p class="step-desc">{{ $lang('若资料审核成功，将联系您评估合作等级及完善资料') }}</p>
          </div>
          <div class="step-item ">
            <p class="step-title">
              <span class="num-1">04</span>{{ $lang('审核结果反馈') }}
            </p>
            <p class="step-desc">{{ $lang('您提交的公司信息将于3个工作日内审核完毕，请关注审核结果，如遇驳回需及时修改') }}</p>
          </div>
        </div>
        <img src="@/assets/bg_auth.png">
      </div>
    </div>
  </div>
  <div class="component-logistics">
    <div class="content">
      <h1 class="second-title">{{ $lang('物流合作') }}</h1>
      <div class="logistics-main">
        <img src="@/assets/bg_logistic.png" alt="">
        <div class="intro-main">{{ $lang('共建物流生态网络') }}
          <p class="p-desc">{{ $lang('能提供国际或国内运输，仓储，快递，清关等服务的服务商') }}</p>
          <p class="p-desc">{{ $lang('依托于行云全球买卖平台，货量有保证') }}</p>
          <p class="p-desc">{{ $lang('商品同品牌合作，货物安全性高') }}</p>
          <div class="join-main">
            <el-button round class="business-travel" @click="settleBtn">{{ $lang('立即入驻') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Cooperation',
  data() {
    return {
      title: `合作双赢，共创辉煌<br>期待与您的合作`,
      content: `智能时代，颠覆传统，引领科学管理<br>欢迎有志之士与吃1顿携手共拓市场`,
    }
  },
  methods: {
    settleBtn() {
      window.open('https://www.3cliques.io/seller')
    }
  }
}
</script>

<style lang="scss" scoped>
.cooperationCon {
  padding-top: 75px;
}
.content {
  max-width: 1240px;
  margin: 0 auto;
  padding: 100px 0;
  .second-title {
    color: #323233;
    font-weight: 600;
    font-size: 44px;
    line-height: 66px;
    text-align: center;
  }
}
.join-main {
  .business-travel {
    margin: 40px 0;
    background-color: #4934b2;
    border-color: #4934b2;
    color: #fff;
  }
  .business-travel:hover {
    background-color: #3d2c96;
  }
}
.components-banner {
  text-align: center;
  background: url('@/assets/bg_lightblue.png') no-repeat;
  background-size: 100% auto;
  .content {
    max-width: 1444px;
    margin: 0 auto;
    padding: 100px 0;
    .main-title {
      margin: 0;
      font-size: 52px;
      line-height: 78px;
      color: #323233;
      font-weight: 600;
    }
    .intro-container {
      display: flex;
      align-items: center;
      margin-top: 40px;
      .flex-item {
        flex: 1 1;
        text-align: center;
        .number-main {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #356DDE;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
        }
        .number-label {
          margin-top: 16px;
          color: #000;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}
.component-cooperation {
  background-color: #f6f7f9;
  .main-intro {
    margin-top: 56px;
    color: #323233;
    font-size: 20px;
    text-align: justify;
  }
  .adv-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    .adv-item {
      display: flex;
      align-items: center;
      width: 392px;
      height: 146px;
      margin-top: 31px;
      padding: 0 19px;
      background-color: #fff;
      border-radius: 12px;
      box-sizing: border-box;
      > div {
        margin-left: 26px;
        color: #646566;
        font-size: 16px;
      }
      img {
        width: 52px;
        height: 52px;
      }
      .p-label {
        margin-bottom: 12px;
        color: #323233;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}
.component-settled {
  .settled-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 68px;
    .steps-main {
      max-width: 520px;
      .step-item {
        position: relative;
        .step-desc {
          position: relative;
          margin-top: 12px;
          margin-left: 22px;
          padding-left: 40px;
          color: #646566;
          font-size: 16px;
          line-height: 150%;
          text-align: justify;
        }
        .step-desc::before {
          position: absolute;
          top: 10px;
          left: 24px;
          width: 6px;
          height: 6px;
          background-color: #4934b2;
          border-radius: 6px;
          content: '';
        }
        .step-title {
          height: 48px;
          margin-top: 20px;
          margin-left: 0;
          padding-left: 0;
          color: #323233;
          font-weight: 600;
          font-size: 20px;
          line-height: 48px;
          .num-1 {
            margin-right: 10px;
            color: #4934b2;
            font-size: 22px;
          }
        }
      }
      .step-item:not(:last-child)::after {
        position: absolute;
        top: 50px;
        bottom: -20px;
        left: 20px;
        width: 2px;
        background-color: rgba(53, 109, 222, 0.2);
        content: '';
      }
    }
    img {
      width: 498px;
      height: auto;
      margin-left: 168px;
    }
  }
}
.component-logistics {
  background-color: #f6f7f9;
  .logistics-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 68px;
    .intro-main {
      margin-left: 68px;
      color: #323233;
      font-size: 20px;
      text-align: justify;
      .p-desc {
        position: relative;
        margin-top: 16px;
        padding-left: 20px;
        color: #646566;
        font-size: 16px;
        line-height: 150%;
      }
      .p-desc::before {
        position: absolute;
        top: 10px;
        left: 0;
        width: 6px;
        height: 6px;
        background-color: #356DDE;
        border-radius: 6px;
        content: '';
      }
    }
    img {
      width: 734px;
    }
  }
}
</style>