<template>
    <div class="product-page">
        <Product-Item
            :bgBanner="bgBanner"
            :title="$lang('仓库管理')"
            :titleDesc="$lang('进销库存清楚直观、多平台库存推送+采购建议防超卖')"
            :subTitle="$lang('精细化的库存及采购管理，实时掌握库存情况')"
            :list="contentList"
            @more="onMore"
        />
        <Process-Order />
    </div>
</template>
  
<script>
  import bgBanner from '@/assets/inventory_bg_banner.png'
  import ProductItem from '@/components/ProductItem.vue'
  import ProcessOrder from '@/components/ProcessOrder.vue'
  export default {
    components: { ProductItem, ProcessOrder },
    data() {
        return {
            bgBanner,
            contentList: [
                {
                    imgUrl: require('@/assets/introduction/inventory_bg_page_part_01_01.png'),
                    title: this.$lang('进销存流程及记录管理'),
                    description: this.$lang('商品库存状态全面监控，合理控制库存，为仓库管理的各个节点提供信息化支持')
                },
                {
                    imgUrl: require('@/assets/introduction/inventory_bg_page_part_01_02.png'),
                    title: this.$lang('各平台店铺库存实时推送'),
                    description: this.$lang('仓库实际库存实时更新到各个线上店铺，及时锁定活动库存，有效避免超卖')
                },
                {
                    imgUrl: 'https://www.bigseller.pro/static/images/introduction/inventory_bg_page_part_01_03.png',
                    title: this.$lang('仓库打包发货流程规范化'),
                    description: this.$lang('支持波次发货，智能聚合同类型订单，合理规划拣货路径，大幅提高仓库打包发货效率')
                }
            ]
        }
    },
    methods: {
        onMore() {
            window.open("https://www.3cliques.io/admin")
        }
    }
  }
</script>